import React, { FormEvent } from 'react'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'

interface IPickMeetingTypeProps {
  setMeetingType: (meetingType: string) => void
  meetingType: string
  errorMessage: string
}

const PickMeetingType: React.FunctionComponent<
  React.PropsWithChildren<IPickMeetingTypeProps>
> = ({ setMeetingType, meetingType, errorMessage }) => {
  const { radioButtonVideoLabel, radioButtonPhoneLabel, meetingTypeHeading } =
    useEpiContent<IContent>()
  const selectMeetingType = React.useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      setMeetingType(e.currentTarget.value)
    },
    [setMeetingType]
  )

  return (
    <FormControl error>
      <RadioGroup
        aria-label={meetingTypeHeading}
        data-testid={'meeting-types-radio-buttons'}
      >
        <FormControlLabel
          control={<Radio />}
          componentsProps={{ typography: { variant: 'subtitle1' } }}
          checked={meetingType === 'onlineMeeting'}
          label={radioButtonVideoLabel}
          name="meetingType"
          value="onlineMeeting"
          onChange={selectMeetingType}
          data-testid="meeting-types-radio-button"
        />
        <FormControlLabel
          control={<Radio />}
          componentsProps={{ typography: { variant: 'subtitle1' } }}
          checked={meetingType === 'phone'}
          label={radioButtonPhoneLabel}
          name="meetingType"
          value="phone"
          onChange={selectMeetingType}
          data-testid="meeting-types-radio-button"
        />
        <FormHelperText sx={{ margin: 0 }}>{errorMessage}</FormHelperText>
      </RadioGroup>
    </FormControl>
  )
}

export default PickMeetingType
