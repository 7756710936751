import React from 'react'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'
import { HTMLMapper } from '@trr/html-mapper'
import { Alert } from '@mui/material'

export const NoAdvisorAssignedView = () => {
  const { advisorMissing } = useEpiContent<IContent>()
  return (
    <Alert
      severity="info"
      sx={{ color: 'neutral.main' }}
      data-testid="advisor-missing"
    >
      <HTMLMapper body={advisorMissing} />
    </Alert>
  )
}
