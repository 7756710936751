import React from 'react'
import {
  Button,
  Typography,
  Dialog,
  IconButton,
  DialogTitle,
  Box,
  colors,
} from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'
import { Close } from '@mui/icons-material'

import ChattingMouthsIllustration from '../../Illustrations/ChattingMouthsIllustration'

interface INoAvailableTimes {
  isOpen: boolean
  onClose: () => void
}
const NoAvailableTimesModal: React.FunctionComponent<
  React.PropsWithChildren<INoAvailableTimes>
> = ({ isOpen, onClose }) => {
  const {
    errorSlotsNotFoundBody,
    errorSlotsNotFoundHeading,
    closeWindowButtonText,
  } = useEpiContent<IContent>()
  return (
    isOpen && (
      <Dialog onClose={onClose} open={isOpen}>
        <Box paddingLeft={'24px'} paddingRight={'24px'}>
          <DialogTitle sx={{ m: 0, p: 5 }}>
            <IconButton
              aria-label={closeWindowButtonText}
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[800],
              }}
            >
              <Close sx={{ color: colors.grey[700] }} />
            </IconButton>
          </DialogTitle>
          <Box sx={{ alignItems: 'center', textAlign: 'center' }}>
            <Box
              sx={{
                marginBottom: '32px',
                svg: {
                  width: '224px',
                  height: '180px',
                },
              }}
            >
              <ChattingMouthsIllustration />
            </Box>
            <Typography variant="h1" sx={{ paddingBottom: '20px' }}>
              {errorSlotsNotFoundHeading}
            </Typography>
            <Typography variant="body1" sx={{ padding: '0 32px 32px 32px' }}>
              {errorSlotsNotFoundBody}
            </Typography>
            <Button sx={{ marginBottom: '48px' }} onClick={onClose}>
              {closeWindowButtonText}
            </Button>
          </Box>
        </Box>
      </Dialog>
    )
  )
}
export default NoAvailableTimesModal
