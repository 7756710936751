import React from 'react'

interface ISvgIcon {
  style?: { [x: string]: unknown }
  width?: number | string
  height?: number
  viewBox: string
  title: string
  description: string
  testSelector?: string
}

const SvgIcon: React.FunctionComponent<React.PropsWithChildren<ISvgIcon>> = ({
  style,
  viewBox,
  title,
  width = 24,
  height = 24,
  testSelector,
  description,
  children,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-labelledby="title desc"
    data-testid={testSelector}
    preserveAspectRatio="xMidYMin slice"
    style={style}
    width={width}
    height={height}
    viewBox={viewBox}
  >
    <title id="title">{title}</title>
    <desc id="desc">{description}</desc>
    {children}
  </svg>
)

export default SvgIcon
