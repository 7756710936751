import React from 'react'
import { Typography, Button, Box } from '@mui/material'

import PositivismIllustration from '../../Illustrations/PositivismIllustration'
import SpinningGearsIllustration from '../../Illustrations/SpinningGearsIllustration'
import ChattingMouthsIllustration from '../../Illustrations/ChattingMouthsIllustration'

export interface IConfirmationContent {
  title: string
  text: string
  contentType?: string
  buttonText: string
  testSelector?: string
  handleCloseModal: () => void
}

export const ConfirmationContent: React.FunctionComponent<
  React.PropsWithChildren<IConfirmationContent>
> = ({
  title,
  text,
  contentType,
  buttonText,
  testSelector,
  handleCloseModal,
}) => {
  return (
    <Box
      role="alert"
      sx={{
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        {contentType === 'success' && (
          <Box
            sx={{
              marginBottom: '32px',
              svg: {
                width: '210px',
                height: '180px',
              },
            }}
          >
            <PositivismIllustration />
          </Box>
        )}
        {contentType === 'pending' && (
          <Box
            sx={{
              marginBottom: '32px',
              svg: {
                width: '258px',
                height: '180px',
              },
            }}
          >
            <SpinningGearsIllustration />
          </Box>
        )}
        {contentType === 'error' && (
          <Box
            sx={{
              marginBottom: '32px',
              svg: {
                width: '224px',
                height: '180px',
              },
            }}
          >
            <ChattingMouthsIllustration />
          </Box>
        )}
      </Box>
      <Box sx={{ padding: { md: '0 64px 32px 64px', xs: '0 24px 16px 24px' } }}>
        <Typography component="h1" variant="h2" sx={{ paddingBottom: '20px' }}>
          {title}
        </Typography>
        <Typography variant="body1">{text}</Typography>
      </Box>
      <Button
        sx={{ marginBottom: '48px' }}
        data-testid={testSelector}
        onClick={handleCloseModal}
      >
        {buttonText}
      </Button>
    </Box>
  )
}
