import React, { useEffect, useState } from 'react'
import { DatePicker } from '@mui/x-date-pickers'
import { date, time } from '@trr/frontend-datetime'
import isSameDay from 'date-fns/isSameDay'
import { IMeeting } from 'Services/API/meetings/meetings.types'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import CustomTextField from 'Components/CustomTextField'
import PickersDayCustom from 'Components/PickersDayCustom'

import { useMeetings } from '../useMeetings'

import {
  getAvailableSlotsOnSpecificDate,
  getFirstDayWithAvailableSlots,
} from './Helpers'

export interface ITimePicker {
  setMeeting: (meeting: IMeeting) => void
  meeting?: IMeeting
  employeeId?: string
  errorMessage?: string
}

const TimePicker: React.FunctionComponent<
  React.PropsWithChildren<ITimePicker>
> = ({ setMeeting, meeting, employeeId, errorMessage }) => {
  const emptyMeetingSlots: IMeeting[] = []

  const [availableMeetingSlots, setAvailableMeetingSlots] =
    useState(emptyMeetingSlots)
  const { meetingsLoading, meetingSlots, meetingSlotsByLatestEmployee } =
    useMeetings()
  const { availableTimesBody } = useEpiContent<IContent>()
  const [activeRadioButton, setActiveRadioButton] = useState('')

  const [meetingDate, setMeetingDate] = useState(
    getFirstDayWithAvailableSlots(availableMeetingSlots)
  )
  const [open, setOpen] = useState(false)

  useEffect(() => {
    meeting.startTime
      ? setMeetingDate(new Date(meeting.startTime))
      : setMeetingDate(getFirstDayWithAvailableSlots(availableMeetingSlots))

    if (employeeId === undefined) {
      return setAvailableMeetingSlots(meetingSlots)
    }
    if (employeeId !== 'all') {
      return setAvailableMeetingSlots(meetingSlotsByLatestEmployee)
    } else {
      return setAvailableMeetingSlots(meetingSlots)
    }
  }, [
    employeeId,
    setAvailableMeetingSlots,
    meetingSlots,
    meetingSlotsByLatestEmployee,
    availableMeetingSlots,
    meeting,
  ])

  useEffect(() => {
    meeting.startTime && setMeetingDate(new Date(meeting.startTime))
  }, [meeting])

  const handleSelectMeetingDate = React.useCallback(
    (date: Date) => {
      setMeetingDate(date)
    },
    [setMeetingDate]
  )

  const handleSelectMeetingSlot = (selectedSlot: IMeeting) => {
    setMeeting(selectedSlot)
  }
  useEffect(() => {
    setActiveRadioButton(meeting.id)
  }, [meeting])

  const availableSlotsOnSelectedDate = getAvailableSlotsOnSpecificDate(
    meetingDate,
    availableMeetingSlots
  )

  if (meetingsLoading) {
    return <CircularProgress data-testid="timepicker-loader" />
  }

  return (
    <>
      <Box sx={{ marginBottom: 4 }}>
        <DatePicker
          onOpen={() => setOpen(true)}
          open={open}
          onClose={() => setOpen(false)}
          label="Datum"
          value={meetingDate}
          onChange={handleSelectMeetingDate}
          minDate={new Date()}
          shouldDisableDate={(day) =>
            availableMeetingSlots.every(
              (el) => !isSameDay(day, new Date(el.startTime))
            )
          }
          slotProps={{
            textField: {
              onClick: () => setOpen(true),
            },
          }}
          slots={{
            textField: CustomTextField,
            day: PickersDayCustom,
          }}
        />
      </Box>

      <Box>
        {availableSlotsOnSelectedDate &&
          availableSlotsOnSelectedDate.length > 0 && (
            <FormControl error>
              <FormLabel id="demo-controlled-radio-buttons-group">
                <Typography
                  variant="body1"
                  fontWeight={'bold'}
                  color={'text.primary'}
                  sx={{ paddingBottom: 1.6 }}
                >
                  {availableTimesBody}{' '}
                  {date.format(new Date(meetingDate), {
                    year: true,
                    weekday: true,
                  })}
                  :
                </Typography>
              </FormLabel>
              <RadioGroup
                sx={{ paddingBottom: 1 }}
                aria-labelledby="demo-controlled-radio-buttons-group"
                data-testid={'time-slots-radio-buttons'}
              >
                {availableSlotsOnSelectedDate.map((slot) => (
                  <FormControlLabel
                    control={<Radio />}
                    data-testid="time-slots-radio-button"
                    componentsProps={{ typography: { variant: 'subtitle1' } }}
                    label={`${time.format(slot.startTime)} – ${time.format(
                      slot.endTime
                    )}`}
                    key={slot.id}
                    checked={activeRadioButton === slot.id}
                    onChange={() => handleSelectMeetingSlot(slot)}
                  />
                ))}
                <FormHelperText sx={{ margin: 0 }}>
                  {errorMessage}
                </FormHelperText>
              </RadioGroup>
            </FormControl>
          )}
      </Box>
    </>
  )
}

export default TimePicker
