import React from 'react'
import {
  ListItem,
  Button,
  ListItemIcon,
  colors,
  ListItemText,
} from '@mui/material'
import { time, date } from '@trr/frontend-datetime'
import { BookingSteps } from 'Meetings/BookMeetingView/HasNoBookingView/HasNoBookingView'
import { differenceInMinutes } from 'date-fns'
import { Event } from '@mui/icons-material'
import { IMeeting } from 'Services/API/meetings/meetings.types'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'

interface ChangeDateTimeProps {
  handleSetStep: (step: number) => void
  meeting: IMeeting
  divider?: boolean
}

const ChangeDateTime: React.FunctionComponent<
  React.PropsWithChildren<ChangeDateTimeProps>
> = ({ handleSetStep, meeting, divider = false }) => {
  const { dateAndTime } = useEpiContent<IContent>()

  return (
    <ListItem
      divider={divider}
      disableGutters
      secondaryAction={
        <Button
          variant="text"
          aria-label="ändra datum och tid"
          onClick={() => handleSetStep(BookingSteps.pickTime)}
        >
          Ändra
        </Button>
      }
    >
      <ListItemIcon>
        <Event sx={{ color: colors.grey[700] }} />
      </ListItemIcon>
      <ListItemText
        primary={dateAndTime}
        primaryTypographyProps={{ variant: 'body1' }}
        secondary={`${date.format(meeting.startTime)}, 
        ${time.format(meeting.startTime)} – ${time.format(
          meeting.endTime
        )} (${differenceInMinutes(
          new Date(meeting.endTime),
          new Date(meeting.startTime)
        )} min)`}
        secondaryTypographyProps={{ variant: 'caption' }}
      />
    </ListItem>
  )
}

export default ChangeDateTime
