import React, { useCallback, useState } from 'react'
import { Button, FormHelperText, Typography } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'
import { IMeeting } from 'Services/API/meetings/meetings.types'
import { useMeetings } from 'Meetings/useMeetings'
import { BookingSteps } from 'Meetings/BookMeetingView/HasNoBookingView/HasNoBookingView'
import { useTrackCustomClick } from '@trr/gtm-tracking'
import getRebookReason from 'Utils/Helpers/getRebookReason'

import RebookMeetingPickTime from './RebookMeetingPickTime'
import RebookMeetingPickMeetingType from './RebookMeetingPickMeetingType'

const emptyMeeting: IMeeting = {
  startTime: '',
  endTime: '',
  id: '',
  employee: {
    id: '',
    fullname: '',
    userPrincipalName: '',
  },
  meetingLocationType: '',
}
interface IRebookMeetingProps {
  bookedMeeting: IMeeting
  handleCancelRebookingButtonClick: () => void
}
const RebookMeeting: React.FunctionComponent<
  React.PropsWithChildren<IRebookMeetingProps>
> = ({ bookedMeeting, handleCancelRebookingButtonClick }) => {
  const [newMeeting, setNewMeeting] = useState(emptyMeeting)
  const [errorMessage, setErrorMessage] = useState('')
  const {
    introBodyRescheduleModal,
    abortRebookingButtonText,
    confirmMeetingRescheduleHeading,
    noChangesMadeErrorMessage,
  } = useEpiContent<IContent>()
  const { sendMeetingRebooking } = useMeetings()
  const [step, setStep] = useState(BookingSteps.pickTime)
  const trackCustomClick = useTrackCustomClick()

  const handleRebookMeetingButtonClick = () => {
    if (!newMeeting.startTime && !newMeeting.meetingLocationType) {
      return setErrorMessage(noChangesMadeErrorMessage)
    }

    sendMeetingRebooking(
      bookedMeeting.id,
      newMeeting,
      newMeeting.meetingLocationType
        ? newMeeting.meetingLocationType
        : bookedMeeting.meetingLocationType
    )

    // Track in GA
    trackCustomClick('click-button', {
      label: `Boka om möte ${getRebookReason(bookedMeeting, newMeeting)}`,
    })
  }

  const handleChangeTimeSlot = useCallback((slot: IMeeting) => {
    setNewMeeting((prev: IMeeting) => ({
      ...slot,
      meetingLocation: prev.meetingLocationType,
    }))
  }, [])

  const handleChangeMeetingType = useCallback((meetingType: string) => {
    setNewMeeting((prev: IMeeting) => ({
      ...prev,
      meetingLocationType: meetingType,
    }))
  }, [])

  const handleSetStep = (step: number) => {
    setErrorMessage('')
    setStep(step)
  }

  return (
    <>
      <Typography variant="body1" sx={{ paddingBottom: 3 }}>
        {introBodyRescheduleModal}
      </Typography>

      {step === BookingSteps.pickTime ? (
        <RebookMeetingPickTime
          bookedMeeting={bookedMeeting}
          newMeeting={newMeeting}
          handleChangeTimeSlot={handleChangeTimeSlot}
          handleSetStep={handleSetStep}
        />
      ) : (
        <RebookMeetingPickMeetingType
          bookedMeeting={bookedMeeting}
          newMeeting={newMeeting}
          handleChangeMeetingType={handleChangeMeetingType}
          handleSetStep={handleSetStep}
        />
      )}

      <Button variant="outlined" onClick={handleCancelRebookingButtonClick}>
        {abortRebookingButtonText}
      </Button>
      <Button
        onClick={handleRebookMeetingButtonClick}
        data-testid={'rebook-meeting-button'}
        sx={{ marginLeft: 2 }}
      >
        {confirmMeetingRescheduleHeading}
      </Button>

      <FormHelperText sx={{ marginTop: 2 }} error>
        {errorMessage}
      </FormHelperText>
    </>
  )
}

export default RebookMeeting
