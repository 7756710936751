import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'
import { HTMLMapper } from '@trr/html-mapper'

const CustomerSupport: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = () => {
  const { contactCenterHeading, contactCenterBody } = useEpiContent<IContent>()
  return (
    <Box data-testid="customer-support" sx={{ paddingTop: 14 }}>
      <Divider sx={{ marginBottom: 4 }} />
      <Typography
        component="h2"
        variant="body1"
        fontWeight={'bold'}
        gutterBottom
      >
        {contactCenterHeading}
      </Typography>
      <HTMLMapper body={contactCenterBody} />
    </Box>
  )
}
export default CustomerSupport
