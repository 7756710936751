import React, {
  useState,
  useCallback,
  useEffect,
  PropsWithChildren,
} from 'react'
import { Box, Typography } from '@mui/material'
import { IMeeting } from 'Services/API/meetings/meetings.types'
import { useEpiContent } from '@trr/app-shell-data'
import { IContent } from 'App.types'
import { HTMLMapper } from '@trr/html-mapper'
import { useTrackCustomClick } from '@trr/gtm-tracking'

import { useMeetings } from '../../useMeetings'
import NoAvailableTimesModal from '../../NoAvailableTimesModal'

import RebookMeeting from './RebookMeeting/'
import MeetingSummary from './MeetingSummary/'

const emptyMeeting: IMeeting = {
  startTime: '',
  endTime: '',
  id: '',
  employee: {
    id: '',
    fullname: '',
    userPrincipalName: '',
  },
  meetingLocationType: 'video',
}

const HasBookingView: React.FunctionComponent<PropsWithChildren> = () => {
  const [isOpenNoTimesModal, setIsOpenNoTimesModal] = useState(false)
  const [isRebooking, setIsRebooking] = useState(false)
  const [currentBooking, setCurrentBooking] = useState(emptyMeeting)
  const {
    bookedMeetings,
    meetingSlots,
    sendMeetingCancellation,
    getMeetingSlots,
  } = useMeetings()
  const trackCustomClick = useTrackCustomClick()

  const content = useEpiContent<IContent>()

  useEffect(() => {
    if (isRebooking) {
      getMeetingSlots()
    }
  }, [isRebooking, getMeetingSlots])

  const handleCancellation = React.useCallback(
    (meeting: IMeeting, message: string) => {
      sendMeetingCancellation(meeting.id, message)
      // Track in GA
      trackCustomClick('click-button', {
        label: `Avbokning av möte ${!message.trim() ? 'utan meddelande' : 'med meddelande'}`,
      })
    },
    [sendMeetingCancellation, trackCustomClick]
  )

  const handleRebooking = React.useCallback(
    (meeting: IMeeting) => {
      if (meetingSlots.length > 0) {
        setIsRebooking(true)
        setCurrentBooking(meeting)
      } else {
        setIsOpenNoTimesModal(true)
      }
    },
    [meetingSlots]
  )

  const handleCloseNoTimesModal = useCallback(() => {
    setIsOpenNoTimesModal(false)
  }, [])

  const handleCancelRebookingButtonClick = () => {
    setIsRebooking(false)
  }

  return (
    <div className="HasBookingView">
      {!isRebooking && (
        <>
          <Box sx={{ paddingBottom: 5 }}>
            <HTMLMapper body={content.introBodyHTML} />
          </Box>
          <Typography component="h2" variant="h5" sx={{ paddingBottom: 4 }}>
            {bookedMeetings.length > 1
              ? content.hasBookingsSummaryHeading
              : content.hasBookingSummaryHeading}
          </Typography>

          {bookedMeetings.map((meeting) => {
            return (
              <MeetingSummary
                meeting={meeting}
                handleCancellation={handleCancellation}
                key={meeting.id}
                handleRebooking={handleRebooking}
              />
            )
          })}
        </>
      )}
      {isRebooking && (
        <RebookMeeting
          bookedMeeting={currentBooking}
          handleCancelRebookingButtonClick={handleCancelRebookingButtonClick}
        />
      )}
      <NoAvailableTimesModal
        isOpen={isOpenNoTimesModal}
        onClose={handleCloseNoTimesModal}
      />
    </div>
  )
}

export default HasBookingView
